<template>
  <div class="card">
    <div id="imputation" class="card-body p-2">
      <div style="width: calc(100% - 40px)">
        <div
          class="d-flex flex-wrap justify-content-between"
          style="margin-top: -63px"
        >
          <span class="my-auto" style="font-size: 22px">Mes activités</span>

          <button
            class="border-0 rounded-pill bg-primary"
            style="width: 40px; height: 40px"
            :style="{
              opacity: selected ? 1 : 0.5,
            }"
            :disabled="!selected"
            @click="createImputation"
          >
            <i style="color: white; cursor: pointer" class="mdi mdi-check"></i>
          </button>
        </div>
      </div>
      <hr class="w-100" />
      <div class="row mb-3">
        <div class="col-3 d-flex align-items-center mb-3">
          <p class="h4 mb-0">Rechercher activité :</p>
        </div>
        <div class="col-9 d-flex align-items-center mb-3">
          <!-- <div class="mr-3">Recherche:</div>    -->
          <div class="d-flex w-100">
            <v-select
              label="text"
              placeholder="Initiatives"
              class="col-md-3 px-0 pl-1"
              type="text"
              v-model="searchQuery.initiative"
              :reduce="(initiative) => initiative.value"
              :options="InitiativeList"
            >
              <template #no-options> Liste vide </template>
            </v-select>
            <date-picker
              placeholder="Début"
              class="col-md-3 px-0 pl-1"
              format="DD/MM/YYYY"
              v-model="searchQuery.createdAt"
            >
            </date-picker>
            <date-picker
              v-model="searchQuery.endingAt"
              placeholder="Fin"
              format="DD/MM/YYYY"
              class="col-md-3 px-0 pl-1"
            >
            </date-picker>
            <div class="col-md-3 px-0 pl-1">
              <v-select
                v-model="selectedStatus"
                placeholder="Statut"
                type="text"
                :options="taskStatus"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            responsive
            ref="selectableTable"
            selectable
            :select-mode="selectMode"
            @row-selected="onRowSelected"
            :fields="activities.tableFields"
            :items="Alltasks"
            hover
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :busy="loadingData"
            show-empty
          >
            <!-- FOR LOADING ANIMATIONS -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- SHOW IF TABLE IS EMPTY -->
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>

            <template v-slot:cell(responsable)="data">
              <TableResponsable :responsable="data.value" />
            </template>

            <template v-slot:cell(status)="data">
              <label>{{ data.value }}</label>
            </template>

            <template v-slot:cell(start_end)="data">
              <div>
                {{ data.item.startedAt + " au " + data.item.deadline }}
              </div>
            </template>
            <template v-slot:cell(phase)="data">
              <div>
                {{ data.value ? data.value.libelle : "-" }}
              </div>
            </template>

            <template v-slot:cell(etp)="data">
              <div>
                {{ data.value || 0 }}
              </div>
            </template>
            <template v-slot:cell(remaining_charges)="data">
              <div>
                {{ data.value }}
              </div>
            </template>

            <template v-slot:cell(initiative)="data">
              <b-link
                v-if="data.item.phase && data.item.phase.initiatives"
                role="link"
                :to="{
                  name: 'show-task',
                  params: {
                    id: data.item.id,
                    slug: data.item.slug || 'abcde',
                  },
                }"
              >
                {{ data.item.phase.initiatives.libelle }}
              </b-link>
              <span v-else>-</span>
            </template>
            <template v-slot:cell(libelle)="data">
              <router-link
                role="link"
                :to="{
                  name: 'show-task',
                  params: {
                    id: data.item.id,
                    slug: data.item.slug || 'abcde',
                  },
                }"
                style="max-width: 100px !important"
                class="text-truncate"
                >{{ data.value }}</router-link
              >
            </template>
            <template v-slot:cell(consumption)="data">
              <b-progress
                max="100"
                show-progress
                height="15px"
                style="color: #cacaca"
              >
                <b-progress-bar
                  :style="`background-color: ${
                    data.item.avancement >= 20 && data.item.avancement <= 70
                      ? '#f5f245'
                      : data.item.avancement > 70
                      ? '#23d92c'
                      : '#f5ba45'
                  }`"
                  :value="data.item.avancement"
                  :label="`${data.item.avancement}%`"
                ></b-progress-bar>
              </b-progress>
            </template>
            <template #cell(selection)="{ rowSelected }">
              <div class="text-center">
                <b-form-checkbox
                  disabled
                  :checked="rowSelected"
                ></b-form-checkbox>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableResponsable from "@/components/custom/TableResponsable.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  components: { TableResponsable },
  props: {
    selectedWeek: String,
  },
  data: () => ({
    tableItems: [],
    sortBy: "name",
    perPage: 4,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loadingData: true,
    selectMode: "single",
    selected: null,
    activities: {
      selectMode: "multi",

      tableFields: [
        { key: "selection", label: "Sélection", sortable: true },
        { key: "initiative", label: "Initiatives", sortable: true },
        { key: "phase", label: "Phase", sortable: true },
        { key: "libelle", label: "Tâche", sortable: true },
        { key: "start_end", label: "Début / Fin", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "etp", label: "Charges estimées", sortable: true },
        {
          key: "remaining_charges",
          label: "Charges restantes",
          sortable: true,
        },
        { key: "consumption", label: "% Consommation", sortable: true },
      ],
    },
    searchQuery: {
      initiative: "",
      createdAt: "",
      endingAt: "",
      status: "",
    },
    selectedStatus: "",
  }),
  methods: {
    onRowSelected(items) {
      this.selected = items[0] || null;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2);
    },
    dateBackgroundColor: function (date) {
      // "#38b000" : "#d20f35";
      moment(date);
      var diff = parseInt(
        moment
          .duration(
            moment(date, "DD-MM-YYYY").diff(moment(new Date(), "DD-MM-YYYY"))
          )
          .asDays()
      );
      return diff < 0 ? "#d20f35" : diff > 4 ? "#38b000" : "#ffd60a";
    },
    createImputation() {
      if (this.selected) {
        this.$store
          .dispatch("imputation/createImputation", {
            task: this.selected["@id"],
            chargesRestantes: 0,
            chargesEstime: 0,
            collaborateur: this.AuthenticatedUser
              ? this.AuthenticatedUser["@id"]
              : "/api/users/17",
          })
          .then(() => {
            this.$emit("close");
            this.$store.dispatch("imputation/fetchAllImputations").then(() => {
              Swal.fire("L'imputation est bien créée !", "", "success");
            });
          });
      }
    },
  },
  created() {
    this.$store.dispatch("initiative/fetchAllInitiatives");
    this.$store.dispatch("status/fetchTaskStatus");
    this.$store.dispatch("imputation/fetchAllImputationsTasks").then(() => {
      this.loadingData = false;
    });
    this.$store.dispatch("task/fetchAllData").then(() => {
      this.loadingData = false;
    });
  },
  computed: {
    ...mapGetters("imputation", ["IMPUTATION", "IMPUTATIONS_TASKS"]),
    ...mapGetters("task", ["ALL_TASKS"]),
    ...mapGetters("initiative", ["INITIATIVES", "VIEW", "TOTAL_ITEMS"]),
    ...mapGetters("status", ["TASK_STATUS"]),
    ...mapGetters(["AuthenticatedUser"]),
    InitiativeList() {
      return this.INITIATIVES.map((initiative) => ({
        value: initiative.id,
        text: initiative.libelle,
      }));
    },

    Alltasks() {
      var status = this.selectedStatus ? this.selectedStatus : "En cours";

      return this.ALL_TASKS.filter(
        (task) => task.responsable["@id"] === this.AuthenticatedUser["@id"]
      )
        .map((task) => {
          var charges = 0;
          task.imputations.map((imputation) => {
            charges += imputation.semaine ? imputation.semaine.totalJour : 0;
          });
          return {
            ...task,
            remaining_charges: (task.etp || 0) - charges,
          };
        })
        .filter(
          (task) =>
            task.status === status &&
            (this.searchQuery.initiative
              ? task.phase.initiatives.id === this.searchQuery.initiative
              : true) &&
            (this.searchQuery.createdAt
              ? moment(task.startedAt).format("DD/MM/YYYY") ===
                moment(this.searchQuery.createdAt, "DD/MM/YYYY").format(
                  "DD/MM/YYYY"
                )
              : true) &&
            (this.searchQuery.endingAt
              ? moment(task.deadline, "DD/MM/YYYY").format("DD/MM/YYYY") ===
                moment(this.searchQuery.endingAt, "DD/MM/YYYY").format(
                  "DD/MM/YYYY"
                )
              : true)
        );
    },
    taskStatus() {
      return this.TASK_STATUS;
    },
    tasksList() {
      return this.IMPUTATIONS_TASKS;
    },
    rows() {
      return this.tableItems.length;
    },
    filteredTasks() {
      var status = this.selectedStatus ? this.selectedStatus : "En cours";
      return this.tableItems.filter(
        (task) =>
          task.status === status &&
          (this.searchQuery.initiative
            ? task.initiative.id === this.searchQuery.initiative
            : true) &&
          (this.searchQuery.createdAt
            ? moment(task.startedAt).format("DD/MM/YYYY") ===
              moment(this.searchQuery.createdAt, "DD/MM/YYYY").format(
                "DD/MM/YYYY"
              )
            : true) &&
          (this.searchQuery.endingAt
            ? moment(task.deadline, "DD/MM/YYYY").format("DD/MM/YYYY") ===
              moment(this.searchQuery.endingAt, "DD/MM/YYYY").format(
                "DD/MM/YYYY"
              )
            : true)
      );
    },
  },
  watch: {
    IMPUTATIONS_TASKS: function () {
      this.tableItems = this.IMPUTATIONS_TASKS.filter(
        (task) => task.status === "En cours"
      );
    },
    selectedStatus: function () {
      var status = this.selectedStatus ? this.selectedStatus : "En cours";
      this.tableItems = this.IMPUTATIONS_TASKS.filter(
        (task) => task.status === status
      );
    },
    IMPUTATION() {
      this.$store
        .dispatch("semaine/updateSemaine", {
          id: this.IMPUTATION.semaine.id,
          semaine: moment(this.selectedWeek || new Date(), "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          ),
        })
        .then(() => {
          this.$emit("close");
          this.selected = null;
          this.$store.dispatch("imputation/fetchAllImputations");
        })
        .then(() => {
          Swal.fire({
            title: "L'imputation est bien créée !",
            type: "success",
            confirmButtonText: "OK",
          });
        });
    },
  },
};
</script>
<style>
#imputation .mx-input,
#imputation .vs__dropdown-toggle {
  height: 46px !important;
}
::-webkit-input-placeholder {
  color: #cfcfcf;
}
.vs__search {
  color: #cfcfcf;
}
</style>
